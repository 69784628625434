.admin-panel {
    display: flex;
    flex-direction: row;

    >div {
        margin: 10px;
    }

    input, select {
        margin: 3px;
    }

    .card {
        margin-bottom: 10px;
    }
}