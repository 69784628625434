html, body, #root, #root > div {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
}
